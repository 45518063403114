import {createSlice} from "@reduxjs/toolkit";

const fieldsSlice = createSlice({
    name: "fields",
    initialState: {},
    reducers: {
        updateFieldSlice: (state, {payload}) => {
            return {
                ...state,
                ...payload,
            };
        },
    }
})

export const {updateFieldSlice} = fieldsSlice.actions;
export default fieldsSlice.reducer;
