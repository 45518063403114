import {createSlice} from "@reduxjs/toolkit";

const serviceReportSlice = createSlice({
    name: "serviceReport",
    initialState: {},
    reducers: {
        updateServiceReportSlice: (state, {payload}) => {
            return {
                ...state,
                ...payload,
            };
        },
    }
})

export const {updateServiceReportSlice} = serviceReportSlice.actions;
export default serviceReportSlice.reducer;