import {createSlice} from "@reduxjs/toolkit";

const userProfile = createSlice({
    name: "userProfile",
    initialState: JSON.parse(localStorage.getItem('user')),
    reducers: {
        updateUserProfileSlice: (state, {payload}) => {
            localStorage.setItem('user', JSON.stringify(payload));
            return {
                ...state,
                ...payload,
            };
        },
        logoutUserProfile: (state, {payload}) => {
            localStorage.removeItem('user');
            localStorage.removeItem('user_permissions');
            return {}
        },
    }
})

export const {updateUserProfileSlice, logoutUserProfile} = userProfile.actions;
export default userProfile.reducer;
