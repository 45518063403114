import {createSlice} from "@reduxjs/toolkit";

const userPermissions = createSlice({
    name: "userPermissions",
    initialState: JSON.parse(localStorage.getItem('user_permissions')),
    reducers: {
        updateUserPermissionSlice: (state, {payload}) => {
            localStorage.setItem('user_permissions', JSON.stringify(payload))
            return {
                ...state,
                ...payload
            }
        },
        removeUserPermissionSlice: (state, {payload}) => {
            localStorage.removeItem('user_permissions')
            return {}
        }
    }
})

export const {updateUserPermissionSlice, removeUserPermissionSlice} = userPermissions.actions;
export default userPermissions.reducer;
