import {createSlice} from "@reduxjs/toolkit";

const fleetServiceReportSlice = createSlice({
    name: "fleetServiceReport",
    initialState: {},
    reducers: {
        updateFleetServiceReportSlice: (state, {payload}) => {
            return {
                ...state,
                ...payload,
            };
        },
    }
})

export const {updateFleetServiceReportSlice} = fleetServiceReportSlice.actions;
export default fleetServiceReportSlice.reducer;
